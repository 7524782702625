import React, { useCallback, useRef, useState, useEffect } from 'react';
import TecheryFullLogoInvertedSvg from '/src/assets/img/techery-full-logo.inline.svg';
import TecheryLogoInvertedSvg from '/src/assets/img/techery-logo-inverted.inline.svg';
import { Modal } from '../modal';
import styles from './workWithUsModal.module.scss';
import { createPortal } from 'react-dom';

const emailVerificationExpr = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

interface WorkWithUsModalProps {
  isModalShown: boolean;
  setIsModalShown: (v: boolean) => void;
}

const WorkWithUsModal = ({
  isModalShown,
  setIsModalShown,
}: WorkWithUsModalProps) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [nameValue, setName] = useState('');
  const [emailValue, setEmail] = useState('');
  const formRef = useRef<HTMLFormElement>(null);
  const handleNameChange = useCallback((event) => {
    setName(event.target.value);

    const isValid = formRef?.current?.checkValidity();

    setIsFormValid(!!isValid);
  }, []);
  const handleEmailChange = useCallback((event) => {
    const email = event.target.value;
    setEmail(email);

    setIsFormValid(emailVerificationExpr.test(email));
  }, []);
  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      formRef?.current?.reportValidity();

      if (isFormValid) {
        try {
          await fetch('/api/work-with-us', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: emailValue,
              name: nameValue,
            }),
          });
          setIsFormSubmitted(true);
        } catch (error) {
          console.error({ error });
        }
      }
    },
    [nameValue, emailValue],
  );
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPortalRoot(typeof document !== `undefined` ? document.body : null);
  }, []);

  return portalRoot
    ? createPortal(
        <Modal
          show={isModalShown}
          showCloseCircle={!isFormSubmitted}
          onClose={() => setIsModalShown(false)}
          renderContent={() =>
            isFormSubmitted ? (
              <div className={styles.WorkWithUsContent}>
                <TecheryFullLogoInvertedSvg className={styles.logo} />
                <h3 className={styles.heading}>Thanks</h3>
                <p className={styles.thanksMessage}>
                  One of our hands-on founders will contact you as soon as
                  possible.
                </p>
                <button
                  className={styles.closeBtn}
                  onClick={() => setIsModalShown(false)}
                >
                  Got It
                </button>
              </div>
            ) : (
              <div className={styles.WorkWithUsContent}>
                <TecheryLogoInvertedSvg className={styles.logo} />
                <h3 className={styles.heading}>Work with Techery</h3>
                <form className={styles.form} ref={formRef}>
                  <input
                    type="text"
                    value={nameValue}
                    onChange={handleNameChange}
                    minLength={2}
                    required
                    placeholder="your name"
                    className={styles.input}
                    autoComplete="off"
                  />
                  <input
                    type="email"
                    value={emailValue}
                    onChange={handleEmailChange}
                    placeholder="your email"
                    required
                    className={styles.input}
                    autoComplete="off"
                  />
                  <button
                    type="submit"
                    className={styles.submitBtn}
                    onClick={handleSubmit}
                    disabled={!isFormValid}
                  >
                    Submit
                  </button>
                </form>
              </div>
            )
          }
        />,
        portalRoot,
      )
    : null;
};

export { WorkWithUsModal };
