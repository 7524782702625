import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { createPortal } from 'react-dom';
import Link from 'next/link';
import CloseCircleSvg from '/src/assets/img/close-circle.inline.svg';
import styles from './mobile-menu.module.scss';
import { urlToLogoMap } from './urlToLogo';

interface MobileMenuProps {
  isOpen: boolean;
  renderCurrentLogo: () => JSX.Element;
  onClose: () => void;
  isJobsPage: boolean;
}

const MobileMenu = ({
  isOpen,
  renderCurrentLogo,
  currentPage,
  onClose,
  isJobsPage,
}: MobileMenuProps & { currentPage: keyof typeof urlToLogoMap }) => {
  const [portalRoot, setPortalRoot] = useState<HTMLElement | null>(null);

  const closeMenu = () => {
    onClose();
  };

  useEffect(() => {
    setPortalRoot(typeof document !== `undefined` ? document.body : null);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  return portalRoot
    ? createPortal(
        <CSSTransition
          in={isOpen}
          timeout={300}
          classNames={styles.mobileMenu}
          unmountOnExit
        >
          <div
            className={
              isJobsPage
                ? styles.mobileMenuJobsContainer
                : styles.mobileMenuContainer
            }
          >
            <div className={styles.content}>
              <div className={styles.navigationLinks}>
                {currentPage === '/jobs' ? (
                  <>
                    <Link
                      href="/jobs#open-jobs"
                      className={styles.link}
                      onClick={closeMenu}
                    >
                      Open Jobs
                    </Link>
                    <Link
                      href="/jobs#about-us"
                      className={styles.link}
                      onClick={closeMenu}
                    >
                      About Us
                    </Link>
                    <Link
                      href="/jobs#our-values"
                      className={styles.link}
                      onClick={closeMenu}
                    >
                      Values
                    </Link>
                    <Link
                      href="/jobs#playlists"
                      className={styles.link}
                      onClick={closeMenu}
                    >
                      Playlists
                    </Link>
                    <Link
                      href="/jobs#hubs"
                      className={styles.link}
                      onClick={closeMenu}
                    >
                      Hubs
                    </Link>
                  </>
                ) : (
                  <>
                    <a href="/#clients" className={styles.link}>
                      About Us
                    </a>
                    {currentPage !== '/blog' && (
                      <Link href="/blog" className={styles.link}>
                        Blog
                      </Link>
                    )}
                    <Link href="/jobs" className={styles.link} target="_blank">
                      Jobs
                    </Link>
                  </>
                )}
              </div>
              <div className={styles.closeCircle}>
                <CloseCircleSvg
                  onClick={onClose}
                  className={styles.closeIcon}
                />
              </div>
            </div>
            <div className={styles.logoLinkContainer}>
              {renderCurrentLogo()}
            </div>
          </div>
        </CSSTransition>,
        portalRoot,
      )
    : null;
};

export default MobileMenu;
