import { vercelStegaCleanAll } from '@sanity/client/stega';

// NOTE: after reviewing current Sanity schema, I decided the next:
//  1. to prevent corruption of system values, enums, styles, types etc
//     they should use lower-cased strings (as now, common practice)
//  2. to define content stuff, we can use texts that starts with upper-case and digits
//  =  So this function clearing Stega for all strings that is started with a-z, other
//     defined as content and should be editable by Stega
//
export const clearStega = <T>(jsonObj: unknown): T => {
  const queue: Array<{ node: unknown; parent: unknown; key: string | null }> = [
    { node: jsonObj, parent: null, key: null },
  ];

  while (queue.length > 0) {
    const item = queue.shift();
    if (!item) {
      continue;
    }
    const { node, parent, key } = item;

    if (typeof node === 'string') {
      if (parent) {
        // NOTE: we assuming the system values is started w/ lowercased ansi symbol (a-z)
        //  a-z is 97-122 in ASCII
        //  but also if there is cleared text is empty, we should remove stega as well (its anyway useless)
        //
        const cleared = vercelStegaCleanAll(node);
        if (
          key &&
          ((node.charCodeAt(0) >= 97 && node.charCodeAt(0) <= 122) ||
            cleared.length <= 0)
        ) {
          parent[key] = cleared;
        }
      }
    } else if (Array.isArray(node)) {
      node.forEach((item, index) =>
        queue.push({ node: item, parent: node, key: String(index) }),
      );
    } else if (typeof node === 'object' && node !== null) {
      Object.keys(node).forEach((k) =>
        queue.push({ node: node[k], parent: node, key: k }),
      );
    }
  }

  return jsonObj as T;
};
