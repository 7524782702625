import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MobileMenu from './mobile-menu';
import Link from 'next/link';
import MobileHumburgerSvg from '../../assets/img/mobile-humburger.inline.svg';
import styles from './header.module.scss';

import { WorkWithUsModal } from '../workWithUsModal';
import { LayoutContainer } from '../layout';
import { HeaderSchema } from '../../schema';
import { clsx } from '@/uikit/utils';
import { urlToLogoMap } from './urlToLogo';
import { jobsPageLinks } from './urlToLogo';

interface HeaderProps {
  data: HeaderSchema;
  setIsModalShown?: (isModalShown: boolean) => void;
}

const Header = ({ data }: HeaderProps) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const { modalLinkText } = data || {};
  const [isMobileMenuShown, setIsMobileMenuShown] = useState(false);
  const [currentPage, setCurrentPage] =
    useState<keyof typeof urlToLogoMap>('/');
  useEffect(() => {
    const keys = Object.keys(urlToLogoMap) as (keyof typeof urlToLogoMap)[];
    const currentUrl = window.location.href;

    setCurrentPage(keys.find((key) => currentUrl.includes(key)) || '/');
  }, []);

  const renderCurrentLogo = useMemo(() => {
    return urlToLogoMap[currentPage];
  }, [currentPage]);

  const workWithUsHandler = useCallback(() => {
    if (currentPage !== '/jobs') {
      setIsModalShown(true);
    } else {
      window.open('mailto:jobs@techery.io');
    }
  }, [currentPage]);

  const isJobsPage: boolean = currentPage === '/jobs';

  return (
    <>
      <LayoutContainer>
        <header className={styles.header} id="header">
          {renderCurrentLogo()}
          <div
            className={
              isJobsPage ? styles.linksJobsContainer : styles.linksContainer
            }
          >
            {currentPage === '/jobs' ? (
              jobsPageLinks
            ) : (
              <>
                <a href="/#clients" className={styles.link}>
                  About Us
                </a>
                {currentPage !== '/blog' && (
                  <Link href="/blog" className={styles.link}>
                    Blog
                  </Link>
                )}
                <Link href="/jobs" className={styles.link}>
                  Jobs
                </Link>
              </>
            )}
            <button
              onClick={workWithUsHandler}
              className={clsx(styles.link, styles.btn)}
            >
              {currentPage === '/jobs' ? 'Contact Us' : modalLinkText}
            </button>

            {!isJobsPage &&
              <button
                className={
                  isJobsPage
                    ? styles.mobileJobsMenuButton
                    : styles.mobileMenuButton
                }
                onClick={() => setIsMobileMenuShown(true)}
              >
                <MobileHumburgerSvg />
              </button>
            }
          </div>
        </header>
      </LayoutContainer>
      {!isJobsPage &&
        <MobileMenu
          isOpen={isMobileMenuShown}
          isJobsPage={isJobsPage}
          renderCurrentLogo={renderCurrentLogo}
          onClose={() => setIsMobileMenuShown(false)}
          currentPage={currentPage}
        />
      }
      {
        <WorkWithUsModal
          isModalShown={isModalShown}
          setIsModalShown={setIsModalShown}
        />
      }
    </>
  );
};

export { Header };
