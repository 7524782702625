import React from 'react';
import { useMountTransition } from '../../hooks/useMountTransition';
import CloseCircleSvg from '/src/assets/img/close-circle.inline.svg';
import styles from './modal.module.scss';
import { clsx } from '@/uikit/utils';

interface ModalProps {
  show?: boolean;
  onClose: () => void;
  showCloseCircle?: boolean;
  renderContent?: any;
}

const Modal = ({
  show,
  onClose,
  showCloseCircle,
  renderContent,
}: ModalProps) => {
  const hasTransitionedIn = useMountTransition(!!show, 600);

  return (
    <>
      {(show || hasTransitionedIn) && (
        <div
          className={clsx(
            styles.modalContainer,
            hasTransitionedIn ? styles.in : '',
            show ? styles.visible : '',
            !showCloseCircle ? styles.skyBg : '',
          )}
        >
          {showCloseCircle && (
            <div className={styles.closeCircle}>
              <CloseCircleSvg onClick={onClose} />
            </div>
          )}
          <div className={styles.content}>{renderContent()}</div>
        </div>
      )}
    </>
  );
};

export { Modal };
