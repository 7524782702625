import { useEffect, useState } from 'react';

import { resolveRef } from '../core/sanityAPI/client-resolvers';
import { PageData } from '../core/sanityAPI/fetchers';
import { Header } from '../components/header';
import { Footer } from '../components/footer';
import { AppProps } from 'next/app';
import '/src/styles/index.scss';
import '/src/styles/blog.scss';
import { ModalContext } from '@/contexts/globalActions';
import { WorkWithUsModal } from '@/components/workWithUsModal';
import posthog from 'posthog-js';
import { clsx } from '@/uikit/utils';
import { themeClass } from '../styles/theme.css';

export default function App({ Component, pageProps }: AppProps<PageData>) {
  const [isModalShown, setIsModalShown] = useState(false);

  const header = resolveRef(pageProps.site?.header);
  const footer = pageProps.site?.footer;

  const showFooter = pageProps.hideFooter !== true;
  const showHeader = pageProps.hideHeader !== true;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const initPostHog = async () => {
        try {
          posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || '', {
            api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
          });
          console.log('PostHog initialized successfully');
        } catch (error) {
          console.error('Failed to load PostHog:', error);
        }
      };
      initPostHog();
    }
  }, []);

  const wrappedClass = clsx(themeClass);

  return (
    <div id="wrapper" className={wrappedClass}>
      {header && (
        <div className="header-container">
          {showHeader && header && (
            <Header data={header} setIsModalShown={setIsModalShown} />
          )}
        </div>
      )}
      <ModalContext.Provider
        value={{ setModalState: () => setIsModalShown(!isModalShown) }}
      >
        <Component {...pageProps} />
      </ModalContext.Provider>

      {showFooter && footer && <Footer data={footer as any} />}
      {
        <WorkWithUsModal
          isModalShown={isModalShown}
          setIsModalShown={setIsModalShown}
        />
      }
    </div>
  );
}
