import React from 'react';
import styles from './layout.module.scss';
import { clsx } from '@/uikit/utils';

interface Props {
  maxWidth?: boolean;
  children: React.ReactNode;
  maxWidthJobs?: boolean;
  disablePadding?: boolean;
}

const LayoutContainer = (props: Props) => {
  return (
    // <div className={`container ${props.maxWidth && 'max-width'}`}>
    <div
      className={clsx(
        styles.container,
        props.maxWidthJobs ? styles.maxWidthJobs : '',
        props.maxWidth ? styles.maxWidth : '',
        props.disablePadding ? styles.noPadding : '',
      )}
    >
      {props.children}
    </div>
  );
};

export { LayoutContainer };
