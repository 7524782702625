import React, { useMemo } from 'react';
import { LayoutContainer } from '../layout';
import TecheryLogoInvertedSvg from '/src/assets/img/techery-logo-inverted.inline.svg';
import TecheryLogoSvg from '/src/assets/img/techery-full-logo.inline.svg';
import styles from './footer.module.scss';
import { clsx } from '@/uikit/utils';
import { FooterSchema } from '@/schema';

interface FooterProps {
  light?: boolean;
  transparent?: boolean;
  data: FooterSchema;
}

const Footer = ({ light, transparent, data }: FooterProps) => {
  const { heading, text, socialLinks } = data || {};
  const year = useMemo(() => new Date().getFullYear(), []);
  const isLight = useMemo(() => !!light, []);
  const isTransparent = useMemo(() => !!transparent, []);

  return (
    <footer
      className={clsx(
        styles.footer,
        (isLight && styles.light) || '',
        (isTransparent && styles.transparent) || '',
      )}
    >
      <LayoutContainer>
        <div className={styles.footerContent}>
          <div className={styles.addingValueContainer}>
            <h4>{heading}</h4>
            <p>{text}</p>
          </div>
          <div className={styles.rightContent}>
            <div className={styles.contactUsContainer}>
              <h4>Contact us</h4>
              <ul>
                {socialLinks?.map(({ href, title }) => (
                  <li key={title}>
                    <a href={href}>{title}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.copyrightContainer}>
              {isLight || isTransparent ? (
                <TecheryLogoSvg className={styles.techeryLogo} />
              ) : (
                <TecheryLogoInvertedSvg className={styles.techeryLogo} />
              )}
              <span>Techery © {year}</span>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </footer>
  );
};

export { Footer };
